import * as React from "react"
import { Box, Flex, Heading, Text, Link, Divider } from "@chakra-ui/react"
import Layout from "../components/Layout"
import ShepherdsContainer from "../components/ShepherdsContainer"

const Faq = () => {
  return (
    <Layout>
      <ShepherdsContainer>
        <Heading mt={8}>Frequently Asked Questions</Heading>
        <Text>
          If you don't see your question answered, please contact us at{" "}
        </Text>
        <Link href="mailto:info@tuskawillashepherds.org" color="red.500">
          Info@TuskawillaShepherds.org
        </Link>
        <Heading size="lg">How can I help?</Heading>
        <Text>
          <Link color="red.500" href="/#donate">
            Donate
          </Link>{" "}
          food, time or money.
        </Text>
        <Heading size="lg">Where can I drop off food?</Heading>
        <Text>Two locations that are always accepting food donations:</Text>
        <Flex>
          <Box as="address">
            <Text>Jean Arthur Associates</Text>
            <Divider />
            <Text>5626 Red Bug Lake Road</Text>
            <Divider />
            <Text>Winter Springs, FL 32708</Text>
          </Box>
          <Box as="address" ml="5">
            <Text>Tuskawilla United Methodist Church</Text>
            <Divider />
            <Text>3925 Red Bug Lake Road</Text>
            <Divider />
            <Text>Casselberry, FL 32707</Text>
          </Box>
        </Flex>
        <Heading size="lg">Is my donation tax deductible?</Heading>
        <Text>Yes! We are a registerd 501(c)3.</Text>
      </ShepherdsContainer>
    </Layout>
  )
}

export default Faq

export const Head = () => <title>FAQ</title>
